import React, { useCallback, useEffect, useState } from 'react';
import { DataGridPro, GridFilterModel, GridRowModel } from '@mui/x-data-grid-pro';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import './DocumentsGrid.scss';
import {
  getChanges,
  getIdsAndSelectedFlag,
  isDocumentEditable,
  updateAllDocs,
  updateDoc,
} from './DocumentsGrid.helpers';
import {
  DocumentGridProps,
  DOCUMENTS_GRID_COLUMNS,
  SpreadSheetDocument,
} from './DocumentsGrid.types';
import { useGetDictionariesQuery } from '../../../store/files/upload/list.service';
import { SPREADSHEET_COLUMNS_DEFINITION } from './DocumentsGridColumns.helpers';
import { NUMBER_OF_ITEMS_ALLOWED } from '../../../config/config';
import DocumentsGridColumnMenu from './DocumentsGridColumnMenu';
import { ColumnMenuHandler } from './DocumentsGridColumnMenu.types';
import { paginationOptions } from '../../StaticComponents/CustomPagination/CustomPagination';
import { getErrorRowClass } from './AchDocumentsGrid.helpers';
import { AccessMode } from '../../../store/files/documentsAndClauses/list.types';
import { useDocumentsListGridScroll } from '../../Documents/DocumentsListGridScroll.hook';

export const DocumentGrid: React.FC<DocumentGridProps> = ({
  setDataChanged,
  search,
  docs,
  isEditingDisabled = false,
  disabledColumns = [],
  validate = false,
  updateStatuses = {},
  isLoading,
  rowCount,
  page,
  pageSize,
  setPage,
  setPageSize,
  className,
  selectionModel,
  onSelectionModelChange,
}) => {
  const ref = useDocumentsListGridScroll();
  const { data: dictionaries } = useGetDictionariesQuery({ AccessMode: AccessMode.WRITE });
  const [rows, setRows] = useState<DocumentGridProps['docs']>([]);
  const [selectedIds, rowsSelected] = getIdsAndSelectedFlag(selectionModel);
  const filterModel: GridFilterModel = {
    items: search
      ? [
          {
            columnField: DOCUMENTS_GRID_COLUMNS.FILE_NAME,
            operatorValue: 'contains',
            value: search,
          },
        ]
      : [],
  };

  useEffect(() => {
    if (!isLoading) {
      setRows(docs);
    }
  }, [docs, isLoading]);

  const handleProcessRowUpdate = useCallback(
    (newRow: GridRowModel) => {
      if (rows.length) {
        const updatedRows = updateDoc(rows, newRow);
        setRows(updatedRows);
        setDataChanged?.(getChanges(docs, updatedRows));
        return newRow;
      }
    },
    [docs, rows, setDataChanged]
  );

  const handleColumnChange: ColumnMenuHandler = (field, value) => {
    if (rows.length && selectedIds.length) {
      const updatedRows = updateAllDocs(rows, selectedIds, field, value);
      setRows(updatedRows);
      setDataChanged?.(getChanges(docs, updatedRows));
    }
  };

  if (!dictionaries || isLoading || !rows.length) {
    return (
      <Box sx={{ textAlign: 'center', padding: '30px' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <div
      style={{ height: '100%', width: '100%' }}
      className={`document-grid ${className ? className : ''}`}
    >
      <DataGridPro
        ref={ref}
        autoHeight={true}
        filterModel={filterModel}
        disableColumnFilter={true}
        getRowId={(row: SpreadSheetDocument) => row.DocumentId}
        columns={SPREADSHEET_COLUMNS_DEFINITION(
          validate,
          updateStatuses,
          dictionaries,
          disabledColumns,
          rowsSelected
        )}
        rows={rows}
        rowCount={rowCount}
        rowsPerPageOptions={NUMBER_OF_ITEMS_ALLOWED}
        pagination
        paginationMode={setPageSize && 'server'}
        page={page}
        pageSize={pageSize}
        onPageChange={setPage}
        onPageSizeChange={setPageSize}
        checkboxSelection
        selectionModel={selectionModel}
        onSelectionModelChange={onSelectionModelChange}
        hideFooterPagination={!setPageSize}
        rowHeight={32}
        headerHeight={32}
        showCellRightBorder
        disableSelectionOnClick
        disableColumnMenu={!setDataChanged || isEditingDisabled}
        components={{
          ColumnMenu: (props) => (
            <DocumentsGridColumnMenu handleColumnChange={handleColumnChange} {...props} />
          ),
        }}
        componentsProps={{
          pagination: paginationOptions(),
        }}
        isCellEditable={({ row: { Status } }) =>
          !isEditingDisabled && !!Status && isDocumentEditable(Status)
        }
        processRowUpdate={handleProcessRowUpdate}
        getRowClassName={({ row: { DocumentId } }) =>
          getErrorRowClass(updateStatuses, DocumentId, 'document-grid-row-error')
        }
        experimentalFeatures={{ newEditingApi: true }}
      />
    </div>
  );
};
