import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { RoutePath } from '../services/route.service';
import {
  hasUserClauseReadAccess,
  hasUserClauseWriteAccess,
  hasUserDocumentReadAccess,
  hasUserDocumentReadReportedAccess,
  hasUserDocumentWriteAccess,
  hasAdminPanelAccess as hasAdminPanelAccessFn,
  hasReportsTabAccessFn,
} from './UserAccess.helper';
import { UserAccessContextValue, UserAccessProviderProps } from './UserAccess.type';
import { AuthorizationResponse } from './authorization.service.types';

const UserAccessContext = React.createContext<UserAccessContextValue>({
  checkUserAccess: () => {},
  userData: undefined,
  hasClauseReadAccess: false,
  hasClauseWriteAccess: false,
  hasDocumentReadAccess: false,
  hasDocumentWriteAccess: false,
  hasReadAccess: false,
  hasWriteAccess: false,
  hasDocumentReadReportedAccess: false,
  hasAdminPanelAccess: false,
  hasReportsTabAccess: false,
});

export const UserAccessProvider: React.FC<UserAccessProviderProps> = ({ children }) => {
  const [userData, setUserData] = useState<AuthorizationResponse>();
  const [hasReadAccess, setHasReadAccess] = useState<boolean>(false);
  const [hasDocumentReadAccess, setHasDocumentReadAccess] = useState<boolean>(false);
  const [hasClauseReadAccess, setHasClauseReadAccess] = useState<boolean>(false);
  const [hasWriteAccess, setHasWriteAccess] = useState<boolean>(false);
  const [hasDocumentWriteAccess, setHasDocumentWriteAccess] = useState<boolean>(false);
  const [hasClauseWriteAccess, setHasClauseWriteAccess] = useState<boolean>(false);
  const [hasDocumentReadReportedAccess, setHasDocumentReadReportedAccess] =
    useState<boolean>(false);
  const [hasAdminPanelAccess, setHasAdminPanelAccess] = useState<boolean>(false);
  const [hasReportsTabAccess, setReportsTabAccess] = useState<boolean>(false);

  const checkUserAccess = useCallback((user) => {
    setUserData(user);
    const cognitoGroups = user.UserGroups;
    const readDocumentAccess = hasUserDocumentReadAccess(cognitoGroups);
    const readClauseAccess = hasUserClauseReadAccess(cognitoGroups);
    setHasReadAccess(readDocumentAccess || readClauseAccess);
    setHasDocumentReadAccess(readDocumentAccess);
    setHasClauseReadAccess(readClauseAccess);
    const writeDocumentAccess = hasUserDocumentWriteAccess(cognitoGroups);
    const writeClauseAccess = hasUserClauseWriteAccess(cognitoGroups);
    setHasWriteAccess(writeDocumentAccess || writeClauseAccess);
    setHasDocumentWriteAccess(writeDocumentAccess);
    setHasClauseWriteAccess(writeClauseAccess);
    const readDocumentReportedAccess = hasUserDocumentReadReportedAccess(cognitoGroups);
    setHasDocumentReadReportedAccess(readDocumentReportedAccess);
    const adminPanelAccess = hasAdminPanelAccessFn(cognitoGroups);
    setHasAdminPanelAccess(adminPanelAccess);
    const ReportsTabAccess = hasReportsTabAccessFn(cognitoGroups);
    setReportsTabAccess(ReportsTabAccess);
  }, []);

  const value = useMemo(
    (): UserAccessContextValue => ({
      checkUserAccess,
      userData,
      hasClauseReadAccess,
      hasClauseWriteAccess,
      hasDocumentReadAccess,
      hasDocumentWriteAccess,
      hasReadAccess,
      hasWriteAccess,
      hasDocumentReadReportedAccess,
      hasAdminPanelAccess,
      hasReportsTabAccess,
    }),
    [
      checkUserAccess,
      userData,
      hasClauseReadAccess,
      hasClauseWriteAccess,
      hasDocumentReadAccess,
      hasDocumentWriteAccess,
      hasReadAccess,
      hasWriteAccess,
      hasDocumentReadReportedAccess,
      hasAdminPanelAccess,
      hasReportsTabAccess,
    ]
  );

  return <UserAccessContext.Provider value={value}>{children}</UserAccessContext.Provider>;
};

export const useUserAccessContext = () => useContext(UserAccessContext);

const useRedirect = (permissions: (keyof UserAccessContextValue)[], noAccessPage: RoutePath) => {
  const navigate = useNavigate();
  const context = useUserAccessContext();

  useEffect(() => {
    if (!permissions.filter((p) => context[p]).length) {
      navigate(noAccessPage);
    }
  }, [context, navigate, noAccessPage, permissions]);
};

export const useReadAccess = () => useRedirect(['hasReadAccess'], RoutePath.NO_ACCESS);
export const useDocumentReadAccess = () =>
  useRedirect(['hasDocumentReadAccess'], RoutePath.NO_ACCESS);
export const useClauseReadAccess = () => useRedirect(['hasClauseReadAccess'], RoutePath.NO_ACCESS);
export const useDocumentWriteAccess = () =>
  useRedirect(['hasDocumentWriteAccess'], RoutePath.NO_WRITE_ACCESS);
export const useClauseWriteAccess = () =>
  useRedirect(['hasClauseWriteAccess'], RoutePath.NO_WRITE_ACCESS);
export const useDocumentReportedAccess = () =>
  useRedirect(
    ['hasDocumentWriteAccess', 'hasDocumentReadReportedAccess'],
    RoutePath.NO_WRITE_ACCESS
  );
export const useAdminPanelAccess = () => useRedirect(['hasAdminPanelAccess'], RoutePath.NOT_FOUND);
