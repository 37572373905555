import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { CLAUSES_LIST_COLUMNS } from '../../../Clauses/ClausesListGrid.types';
import { ClauseItemForRelatedDocs } from './ClauseItemForRelatedDocs';
import { OpenClause } from './OpenClause';

export const COLUMNS_DEFINITION = (): GridColDef[] => {
  return [
    {
      sortable: false,
      flex: 12,
      field: CLAUSES_LIST_COLUMNS.CLAUSE_NAME,
      hideable: false,
      cellClassName: 'clause-item-cell',
      renderCell: ({ row }: GridRenderCellParams) => {
        return <ClauseItemForRelatedDocs clause={row} />;
      },
    },
    {
      sortable: false,
      flex: 1.5,
      field: '',
      hideable: false,
      renderCell: ({ row }: GridRenderCellParams) => {
        return <OpenClause clause={row} />;
      },
    },
  ];
};
