const highlight = '<mark>$1</mark>';
const matchOutsideHtmlTags = '(?!([^<]+)?>)';

const searchHighlightDefault = (text: string, phrase: string) => {
  const keywords = phrase.split(' ');
  const regex = new RegExp(`(${keywords.join('|')})${matchOutsideHtmlTags}`, 'gi');
  return text.replaceAll(regex, highlight);
};

const searchHighlightExact = (text: string, phrase: string) => {
  const regex = new RegExp(`(${phrase})${matchOutsideHtmlTags}`, 'gi');
  return text.replaceAll(regex, highlight);
};

export const searchHighlight = (text: string, phrase?: string, exact = false) =>
  phrase
    ? exact
      ? searchHighlightExact(text, phrase)
      : searchHighlightDefault(text, phrase)
    : text;
