import React, { useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import UploadService from '../../../store/files/upload/upload.service';
import { FileViewerProps } from './FileViewer.types';
import { FileLoader } from './FileLoader';
import { useFileLoader } from './FileLoader.hook';
import './FileViewer.scss';
import { Document, Page, pdfjs } from 'react-pdf';
import type { PDFDocumentProxy } from 'pdfjs-dist';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import { useZoomSwitch } from './FileViewer.helpers';
import { ViewerToolbar } from './ViewerToolbar/ViewerToolbar';
// @ts-ignore
import pdfJsWorker from 'pdfjs-dist/build/pdf.worker.entry';
import {
  copyEventListener,
  useScrollSyncEffect,
  useTextSelectionDispatch,
} from './FileViewer.hook';
import { useHighlight } from './HtmlViewer.highlight';
import { customTextRenderer, generatePages, handleFnOnAllPagesRendered } from './PdfViewer.helpers';
import { PdfDocumentProps, PdfViewerHelperProps, PdfViewerHelperRef } from './PdfViewer.types';
import { useClauseHighlight } from './HtmlViewer.clause-highlight';

pdfjs.GlobalWorkerOptions.workerSrc = pdfJsWorker;

const PdfViewer = ({
  loading,
  error,
  url,
  keyword,
  method,
  searchOption,
  clauses,
}: FileViewerProps) => {
  const { isLoading, isError, setLoading, setError } = useFileLoader(loading, error);
  const ref = useRef<HTMLDivElement | null>(null);
  const helperRef = useRef<PdfViewerHelperRef>(null);
  const { scale, handleZoom } = useZoomSwitch(url);

  const [pdfRendered, setPdfRendered] = useState(false);

  useScrollSyncEffect(() => ref.current, pdfRendered);

  const onRenderSuccess = () => {
    helperRef.current?.initHighlight();
    setPdfRendered(true);
  };

  const { handleTextSelectionDispatch } = useTextSelectionDispatch();

  const handleTextSelection = () => {
    const selectionObj = window.getSelection();
    handleTextSelectionDispatch(selectionObj);
  };

  useEffect(() => {
    const copyFunction = copyEventListener(window);
    document.addEventListener('copy', copyFunction);

    return () => {
      document.removeEventListener('copy', copyFunction);
    };
  }, []);

  useEffect(() => {
    if (isLoading || scale > -1) setPdfRendered(false);
  }, [isLoading, scale]);

  useEffect(() => {
    if (url) {
      setLoading(true);
      setError(false);
      UploadService.preflightDocumentDownload(url)
        .catch(() => setError(true))
        .then(() => setLoading(false));
    }
  }, [setError, setLoading, url]);

  return (
    <div className='file-viewer file-viewer--pdf'>
      {isLoading || isError || !url ? (
        <FileLoader loading={isLoading} error={isError} />
      ) : (
        <>
          <PdfDocument
            ref={ref}
            url={url}
            scale={scale}
            onMouseUp={handleTextSelection}
            onRenderSuccess={onRenderSuccess}
          />

          <PdfViewerHelper
            ref={helperRef}
            pdfRef={ref}
            rendered={pdfRendered}
            keyword={keyword}
            clauses={clauses}
            handleZoom={handleZoom}
            searchOption={searchOption}
            method={method}
          />
        </>
      )}
    </div>
  );
};

const PdfDocument = React.forwardRef<HTMLDivElement, PdfDocumentProps>(
  ({ url, scale, onRenderSuccess, onMouseUp }, ref) => {
    const [pages, setPages] = useState<number[]>([]);
    const renderedPages = useRef<number[]>([]);

    const handleLoadSuccess = useCallback(({ numPages }: PDFDocumentProxy) => {
      setPages(generatePages(numPages));
    }, []);

    const handleRenderTextLayerSuccess = useCallback(
      (page: number) => {
        renderedPages.current = handleFnOnAllPagesRendered(
          renderedPages.current,
          page,
          pages.length,
          onRenderSuccess
        );
      },
      [onRenderSuccess, pages.length]
    );

    return (
      <Document
        className='file-viewer--document'
        inputRef={ref}
        file={url}
        loading=''
        onLoadSuccess={handleLoadSuccess}
        onMouseUp={onMouseUp}
      >
        {pages.map((page) => (
          <Page
            key={`page_${page}`}
            pageNumber={page}
            scale={scale}
            loading=''
            customTextRenderer={customTextRenderer}
            onRenderTextLayerSuccess={() => handleRenderTextLayerSuccess(page)}
          />
        ))}
      </Document>
    );
  }
);

const PdfViewerHelper = React.forwardRef<PdfViewerHelperRef, PdfViewerHelperProps>(
  ({ pdfRef, rendered, handleZoom, keyword, method, searchOption, clauses }, ref) => {
    const { handleHighlight, handleSwitch } = useHighlight(pdfRef, keyword, method);
    useClauseHighlight(pdfRef, rendered, clauses);

    useImperativeHandle(ref, () => ({
      initHighlight() {
        handleHighlight();
      },
    }));

    return (
      <ViewerToolbar
        handleZoom={handleZoom}
        handleSwitch={handleSwitch}
        searchOption={searchOption}
      />
    );
  }
);

export default React.memo(PdfViewer);
