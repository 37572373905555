import React from 'react';
import IconButton from '@mui/material/IconButton';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { listApi, useDeleteFileMutation } from '../../../store/files/upload/list.service';
import { useAppDispatch } from '../../../store/hooks';
import {
  UPLOADED_FILE_TYPE,
  UploadedFileDeleteButtonProps,
} from './UploadedFileDeleteButton.types';
import { useConfirmationModal } from '../../StaticComponents/Modals/ConfirmationModal/ConfirmationModal.hook';
import { DeleteConfirmModal } from '../../StaticComponents/Modals/ConfirmationModal/DeleteConfirmModal';
import { TEST_ID } from '../../../config/test-fields-ids.config';
import { LIST_API_TAGS } from '../../../store/files/upload/list.service.types';
import { ErrorMessages } from '../../../services/errors.service.types';
import { useSnackbar } from 'notistack';
import { UploadedFileStatus } from '../UploadedFileList.types';
import { showStatus } from '../UploadedFileList.helper';
import {
  CLAUSE,
  DELETE_MODAL_MESSAGE,
  DELETE_MODAL_TITLE,
  DOCUMENT,
} from '../../StaticComponents/Modals/ConfirmationModal/ConfirmationModal.messages';
import {
  clausesListApi,
  useDeleteClauseMutation,
} from '../../../store/files/clauses/clauses.list.service';
import { showLoader } from '../../../store/ui/ui.slice';
import { timeoutPromise } from '../../../helpers/app.helpers';
import { REFRESH_DELAY_AFTER_OPEN_SEARCH_ACTION } from '../../../config/config';
import { CLAUSES_LIST_API_TAGS } from '../../../store/files/clauses/clauses.list.types';
import {
  documentsListApi,
  useDeleteDocumentMutation,
} from '../../../store/files/documents/documents.list.service';
import { DOCUMENTS_LIST_API_TAGS } from '../../../store/files/documents/documents.list.types';

export const UploadedFileDeleteButton = ({ FileId, type }: UploadedFileDeleteButtonProps) => {
  const dispatch = useAppDispatch();
  const [deletePreLandingDocument] = useDeleteFileMutation();
  const [deletePendingDocument] = useDeleteDocumentMutation();
  const [deleteClause] = useDeleteClauseMutation();
  const { enqueueSnackbar } = useSnackbar();
  const { open, handleOpen, handleClose } = useConfirmationModal();

  const title = DELETE_MODAL_TITLE(type === UPLOADED_FILE_TYPE.PENDING_CLAUSE ? CLAUSE : DOCUMENT);
  const message = DELETE_MODAL_MESSAGE(
    type === UPLOADED_FILE_TYPE.PENDING_CLAUSE ? CLAUSE : DOCUMENT
  );

  const handleDeleteDocumentConfirm = async () => {
    dispatch(showStatus(FileId, UploadedFileStatus.DELETING));

    const deleteFunction =
      type === UPLOADED_FILE_TYPE.PRE_LANDING_DOC
        ? deletePreLandingDocument
        : deletePendingDocument;

    const refreshFunction = async () => {
      if (type === UPLOADED_FILE_TYPE.PRE_LANDING_DOC) {
        dispatch(listApi.util.invalidateTags([LIST_API_TAGS.LIST]));
      } else {
        dispatch(showLoader(true));
        await timeoutPromise(REFRESH_DELAY_AFTER_OPEN_SEARCH_ACTION);
        dispatch(documentsListApi.util.invalidateTags([DOCUMENTS_LIST_API_TAGS.DOCUMENTS_LIST]));
        dispatch(showLoader(false));
      }
    };

    deleteFunction(FileId)
      .unwrap()
      .catch(() => enqueueSnackbar(ErrorMessages.FileDeleteError, { variant: 'error' }))
      .then(() => refreshFunction());
  };

  const handleDeleteClauseConfirm = async () => {
    try {
      dispatch(showLoader(true));
      await deleteClause(FileId).unwrap();
      await timeoutPromise(REFRESH_DELAY_AFTER_OPEN_SEARCH_ACTION);
      dispatch(clausesListApi.util.invalidateTags([CLAUSES_LIST_API_TAGS.CLAUSES_LIST]));
    } catch (error) {
      enqueueSnackbar(ErrorMessages.FileDeleteError, { variant: 'error' });
    } finally {
      dispatch(showLoader(false));
    }
  };

  return (
    <>
      <IconButton onClick={handleOpen}>
        <DeleteOutlineOutlinedIcon
          data-test-id={TEST_ID.UPLOAD_FILES.DELETE_UPLOADED_DOCUMENT}
          fontSize='small'
        />
      </IconButton>
      <DeleteConfirmModal
        open={open}
        onClose={handleClose()}
        onConfirm={
          type === UPLOADED_FILE_TYPE.PENDING_CLAUSE
            ? handleDeleteClauseConfirm
            : handleDeleteDocumentConfirm
        }
        title={title}
        message={message}
      />
    </>
  );
};
