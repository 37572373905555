import { useDispatch } from 'react-redux';
import { clearSelectedText, setSelectedText } from '../../../store/files/documents/documents.slice';
import { useEffect } from 'react';
import { useScrollSync } from '../../Documents/DocumentViewModal/ScrollSyncContext';

const transformText = (selectionObj: Selection | null) => {
  return selectionObj?.toString().replaceAll('-\n', '').replaceAll('\n', ' ');
};

export const useTextSelectionDispatch = () => {
  const dispatch = useDispatch();

  const handleDispatchTextSelection = (selectionObj: Selection | null) => {
    const text = transformText(selectionObj);

    if (text) {
      dispatch(setSelectedText(text));
    } else {
      dispatch(clearSelectedText());
    }
  };

  return { handleTextSelectionDispatch: handleDispatchTextSelection };
};

export const copyEventListener = (window: Window) => (event: ClipboardEvent) => {
  const selectionObj = window.getSelection();
  const transformedText = transformText(selectionObj);
  if (event.clipboardData && transformedText) {
    event.clipboardData.setData('text/plain', transformedText);
    event.preventDefault();
  }
};

export const useScrollSyncEffect = (
  getScrollableElement: () => HTMLElement | Window | null,
  isContentLoaded: boolean
) => {
  const { syncedScroll, setSyncedScroll, isScrollSyncEnabled } = useScrollSync();

  useEffect(() => {
    const scrollableElement = getScrollableElement();

    if (scrollableElement && isScrollSyncEnabled && isContentLoaded) {
      const handleScroll = () => {
        let scrollPosition: number;

        if ('scrollTop' in scrollableElement) {
          scrollPosition = scrollableElement.scrollTop; //PDF
        } else {
          scrollPosition = //IFrame
            scrollableElement.document.body.scrollTop ||
            scrollableElement.document.documentElement.scrollTop;
        }
        setSyncedScroll(scrollPosition);
      };

      scrollableElement.addEventListener('scroll', handleScroll);

      return () => {
        scrollableElement.removeEventListener('scroll', handleScroll);
      };
    }
  }, [getScrollableElement, isContentLoaded, isScrollSyncEnabled, setSyncedScroll, syncedScroll]);

  useEffect(() => {
    const scrollableElement = getScrollableElement();

    if (scrollableElement && isScrollSyncEnabled && isContentLoaded) {
      if ('scrollTop' in scrollableElement) {
        scrollableElement.scrollTop = syncedScroll; //PDF
      } else {
        scrollableElement.scrollTo(0, syncedScroll); //IFrame
      }
    }
  }, [getScrollableElement, syncedScroll, isScrollSyncEnabled, isContentLoaded]);
};
