import Menu from '@mui/material/Menu';
import './DocumentContextMenu.scss';
import { DOCUMENT_CONTEXT_MENU_ITEM_NAME, ContextMenuProps } from './DocumentContextMenu.types';
import { menuItemDisabled, menuItemVisible } from './DocumentContextMenu.helpers';
import { DocumentContextMenuEdit } from './DocumentContextMenuEdit';
import { DocumentContextMenuDelete } from './DocumentContextMenuDelete';
import { DocumentContextMenuDownload } from './DocumentContextMenuDownload';
import { DocumentContextMenuCompare } from './DocumentContextMenuCompare';
import { DocumentContextMenuBookmark } from './DocumentContextMenuBookmark';
import { BookmarkContextMenuDeleteItem } from '../../Bookmarks/BookmarkContextMenuDeleteItem';
import { BookmarkContextMenuMove } from '../../Bookmarks/BookmarkContextMenuMove';
import { Document } from '../../../store/files/documents/documents.list.types';
import { Clause } from '../../../store/files/clauses/clauses.list.types';
import { DocumentContextMenuReport } from './DocumentContextMenuReport';
import { DocumentContextMenuShowRelated } from './DocumentContextMenuShowRelated';
import { DocumentFilesCompareContextMenuButton } from '../DocumentFilesCompareModal/DocumentFilesCompareContextMenuButton';

export const DocumentContextMenu = ({
  control: { selectedItem, anchorEl, onClose },
  documentContextMenuItems,
}: ContextMenuProps<Document | Clause>) => {
  return (
    <Menu
      className='documents-list-menu'
      open={anchorEl !== null}
      onClose={onClose}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      {menuItemVisible(documentContextMenuItems, DOCUMENT_CONTEXT_MENU_ITEM_NAME.FILES_COMPARE) ? (
        <DocumentFilesCompareContextMenuButton document={selectedItem} onClose={onClose} />
      ) : null}
      {menuItemVisible(documentContextMenuItems, DOCUMENT_CONTEXT_MENU_ITEM_NAME.BOOKMARK) ? (
        <DocumentContextMenuBookmark
          document={selectedItem}
          onClose={onClose}
          disabled={menuItemDisabled(
            documentContextMenuItems,
            DOCUMENT_CONTEXT_MENU_ITEM_NAME.BOOKMARK
          )}
        />
      ) : null}
      {menuItemVisible(documentContextMenuItems, DOCUMENT_CONTEXT_MENU_ITEM_NAME.COMPARE) ? (
        <DocumentContextMenuCompare
          disabled={menuItemDisabled(
            documentContextMenuItems,
            DOCUMENT_CONTEXT_MENU_ITEM_NAME.COMPARE
          )}
          document={selectedItem}
          onClose={onClose}
        />
      ) : null}
      {menuItemVisible(documentContextMenuItems, DOCUMENT_CONTEXT_MENU_ITEM_NAME.DOWNLOAD) ? (
        <DocumentContextMenuDownload
          document={selectedItem}
          onClose={onClose}
          disabled={menuItemDisabled(
            documentContextMenuItems,
            DOCUMENT_CONTEXT_MENU_ITEM_NAME.DOWNLOAD
          )}
        />
      ) : null}
      {menuItemVisible(documentContextMenuItems, DOCUMENT_CONTEXT_MENU_ITEM_NAME.EDIT) ? (
        <DocumentContextMenuEdit
          document={selectedItem}
          onClose={onClose}
          disabled={menuItemDisabled(
            documentContextMenuItems,
            DOCUMENT_CONTEXT_MENU_ITEM_NAME.EDIT
          )}
        />
      ) : null}
      {menuItemVisible(documentContextMenuItems, DOCUMENT_CONTEXT_MENU_ITEM_NAME.DELETE) ? (
        <DocumentContextMenuDelete
          document={selectedItem}
          onClose={onClose}
          disabled={menuItemDisabled(
            documentContextMenuItems,
            DOCUMENT_CONTEXT_MENU_ITEM_NAME.DELETE
          )}
        />
      ) : null}
      {menuItemVisible(documentContextMenuItems, DOCUMENT_CONTEXT_MENU_ITEM_NAME.REPORT) ? (
        <DocumentContextMenuReport
          document={selectedItem}
          onClose={onClose}
          disabled={menuItemDisabled(
            documentContextMenuItems,
            DOCUMENT_CONTEXT_MENU_ITEM_NAME.REPORT
          )}
        />
      ) : null}
      {menuItemVisible(
        documentContextMenuItems,
        DOCUMENT_CONTEXT_MENU_ITEM_NAME.REMOVE_FROM_BOOKMARK
      ) ? (
        <BookmarkContextMenuDeleteItem
          document={selectedItem}
          onClose={onClose}
          disabled={menuItemDisabled(
            documentContextMenuItems,
            DOCUMENT_CONTEXT_MENU_ITEM_NAME.REMOVE_FROM_BOOKMARK
          )}
        />
      ) : null}
      {menuItemVisible(documentContextMenuItems, DOCUMENT_CONTEXT_MENU_ITEM_NAME.MOVE) ? (
        <BookmarkContextMenuMove document={selectedItem} onClose={onClose} />
      ) : null}
      {menuItemVisible(documentContextMenuItems, DOCUMENT_CONTEXT_MENU_ITEM_NAME.SHOW_RELATED) ? (
        <DocumentContextMenuShowRelated document={selectedItem} onClose={onClose} />
      ) : null}
    </Menu>
  );
};
