import { Clause } from '../../../../store/files/clauses/clauses.list.types';
import { useDocumentRelatedClauses } from './ClauseRelatedDocuments.hooks';
import IconButton from '@mui/material/IconButton';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

export const OpenClause = ({ clause: { ClauseId } }: { clause: Clause }) => {
  const { openNewClauseViewModal } = useDocumentRelatedClauses();

  const handleClick = () => {
    openNewClauseViewModal && openNewClauseViewModal(ClauseId);
  };

  return (
    <IconButton onClick={handleClick}>
      <OpenInNewIcon />
    </IconButton>
  );
};
