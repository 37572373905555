import { CircularProgress, Paper, Tooltip } from '@mui/material';
import './Documents.scss';
import { DocumentsCountProps, MessageForFiltersFn } from './DocumentsCount.types';
import {
  generateMessage,
  getExcluded,
  getLang,
  getMethod,
  getText,
  messageForCounter,
} from './DocumentsCount.helpers';
import { SaveSearch } from './SaveSearch';
import { TEST_ID } from '../../config/test-fields-ids.config';
import { notEmptySearchQuery } from './DocumentsSearch/DocumentsSearch.helpers';

const messageForFilters: MessageForFiltersFn = (filters, clauseMode, saveSearchDisabled) => {
  const message = generateMessage(filters, clauseMode);
  return message ? (
    <>
      <span>
        {' '}
        for: <b>{message}</b>
      </span>
      {saveSearchDisabled ? null : <SaveSearch filters={filters} clauseMode={clauseMode} />}
    </>
  ) : null;
};

export const DocumentsCount = ({
  useGetDataListWithParams,
  clauseMode = false,
  saveSearchDisabled = false,
}: DocumentsCountProps) => {
  const { data, filters, isLoading, isFetching } = useGetDataListWithParams();
  const count = data
    ? 'TotalDocumentCount' in data
      ? data?.TotalDocumentCount
      : data?.TotalClauseCount
    : 0;
  return (
    <Paper
      elevation={0}
      data-test-id={TEST_ID.DOCUMENTS_PAGE.DOCUMENTS_PAGE_DOCUMENTS_COUNTER}
      className='documents-count'
    >
      <div>
        {isLoading || isFetching ? (
          <CircularProgress size={18} />
        ) : count >= 0 ? (
          <span>
            {messageForCounter(count)}
            {messageForFilters(filters, clauseMode, saveSearchDisabled)}
          </span>
        ) : (
          <span className='documents-count-error'>Error calculating results</span>
        )}
      </div>

      <div className='search-queries'>
        {filters.Queries?.filter(notEmptySearchQuery).map(
          ({ SearchQuery = '', SearchMethod, OriginalLanguage, ExcludePhrases }, index) => {
            return (
              <span className='search-queries-item' key={index}>
                <Tooltip title={SearchQuery}>
                  <strong>{getText(SearchQuery)}</strong>
                </Tooltip>
                <span>{getMethod(SearchMethod)}</span>
                <span>{getLang(OriginalLanguage)}</span>
                <span>{getExcluded(ExcludePhrases)}</span>
              </span>
            );
          }
        )}
      </div>
    </Paper>
  );
};
