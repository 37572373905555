import { Paper } from '@mui/material';
import { DocumentsListGrid } from './DocumentsListGrid';
import './Documents.scss';
import { DocumentsListProps } from './Documents.types';
import { AchMetadataEditModal } from '../UploadFiles/MetadataEditModal/AchMetadataEditModal';
import { DocumentViewModal } from './DocumentViewModal/DocumentViewModal';
import { DocumentCompareModal } from './DocumentCompareModal/DocumentCompareModal';
import { DocumentFilesCompareModal } from './DocumentFilesCompareModal/DocumentFilesCompareModal';

export const DocumentsList = ({ menuOptions, useGetDataListWithParams }: DocumentsListProps) => {
  return (
    <Paper elevation={0} className='documents-list'>
      <DocumentsListGrid
        menuOptions={menuOptions}
        useGetDataListWithParams={useGetDataListWithParams}
      />
      <AchMetadataEditModal useGetDataListWithParams={useGetDataListWithParams} />
      <DocumentViewModal useGetDataListWithParams={useGetDataListWithParams} />
      <DocumentCompareModal />
      <DocumentFilesCompareModal />
    </Paper>
  );
};
