import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Document, DocumentsListParams } from './documents.list.types';
import { INIT_NUMBER_OF_DOCUMENTS } from '../../../config/config';
import DownloadService from '../download.service';
import { defaultSequentialSearch } from '../upload/list.helpers';

export const defaultListQueryParams = (): DocumentsListParams => ({
  PageNumber: '1',
  PageSize: String(INIT_NUMBER_OF_DOCUMENTS),
  Queries: [defaultSequentialSearch()],
});

export interface DocumentsState {
  metadataEditModal?: Document;
  documentViewModal?: Document;
  documentFilesCompareModal?: Document;
  versionsQueryParams: DocumentsListParams;
  listQueryParams: DocumentsListParams;
  pendingListQueryParams: DocumentsListParams;
  uploadedDocumentsListQueryParams: DocumentsListParams;
  reportedDocumentsListQueryParams: DocumentsListParams;
  selectedText: string;
}

const initialState: DocumentsState = {
  metadataEditModal: undefined,
  documentViewModal: undefined,
  documentFilesCompareModal: undefined,
  versionsQueryParams: defaultListQueryParams(),
  listQueryParams: defaultListQueryParams(),
  pendingListQueryParams: defaultListQueryParams(),
  uploadedDocumentsListQueryParams: defaultListQueryParams(),
  reportedDocumentsListQueryParams: defaultListQueryParams(),
  selectedText: '',
};

export const documentsSlice = createSlice({
  name: 'documents',
  initialState,
  reducers: {
    openMetadataEditModal: (
      state: DocumentsState,
      action: PayloadAction<{ document: Document }>
    ) => {
      state.metadataEditModal = action.payload.document;
    },
    closeMetadataEditModal: (state: DocumentsState) => {
      state.metadataEditModal = undefined;
    },

    openDocumentViewModal: (
      state: DocumentsState,
      action: PayloadAction<{ document: Document }>
    ) => {
      state.documentViewModal = action.payload.document;
    },
    closeDocumentViewModal: (state: DocumentsState) => {
      state.documentViewModal = undefined;
    },

    openDocumentFilesCompareModal: (
      state: DocumentsState,
      action: PayloadAction<{ document: Document }>
    ) => {
      state.documentFilesCompareModal = action.payload.document;
    },
    closeDocumentFilesCompareModal: (state: DocumentsState) => {
      state.documentFilesCompareModal = undefined;
    },

    updateListQueryParams: (state: DocumentsState, action: PayloadAction<DocumentsListParams>) => {
      state.listQueryParams = action.payload;
    },
    updatePendingListQueryParams: (
      state: DocumentsState,
      action: PayloadAction<DocumentsListParams>
    ) => {
      state.pendingListQueryParams = action.payload;
    },
    updateUploadedDocumentsListQueryParams: (
      state: DocumentsState,
      action: PayloadAction<DocumentsListParams>
    ) => {
      state.uploadedDocumentsListQueryParams = action.payload;
    },
    updateReportedDocumentsListQueryParams: (
      state: DocumentsState,
      action: PayloadAction<DocumentsListParams>
    ) => {
      state.reportedDocumentsListQueryParams = action.payload;
    },
    setSelectedText: (state: DocumentsState, action: PayloadAction<string>) => {
      state.selectedText = action.payload;
    },
    clearSelectedText: (state: DocumentsState) => {
      state.selectedText = '';
    },
  },
});

export const downloadDocument = createAsyncThunk<void, { fileName: string; PreSignedUrl: string }>(
  'document/download/pdf',
  async ({ fileName, PreSignedUrl }) => {
    await DownloadService.downloadDocument(PreSignedUrl, fileName);
  }
);

export const {
  openMetadataEditModal,
  closeMetadataEditModal,
  openDocumentViewModal,
  closeDocumentViewModal,
  openDocumentFilesCompareModal,
  closeDocumentFilesCompareModal,
  updateListQueryParams,
  updatePendingListQueryParams,
  updateUploadedDocumentsListQueryParams,
  updateReportedDocumentsListQueryParams,
  setSelectedText,
  clearSelectedText,
} = documentsSlice.actions;

export default documentsSlice.reducer;
