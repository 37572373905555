import { Chip } from '@mui/material';
import { Clause } from '../../../../store/files/clauses/clauses.list.types';
import { ProviderChip } from '../../../Clauses/ClauseItem/ClauseItem.helper';
import { generatePreview } from '../../../Clauses/ClausesListGrid.helpers';
import { ClauseStatusIcon } from '../../../Clauses/ClauseStatusIcon/ClauseStatusIcon';
import { ItemNameText } from '../../../DocumentsAndClauses/ItemNameText/ItemNameText';
import { setScrollToClauseInDoc } from '../../../../store/ui/ui.slice';
import { useAppDispatch } from '../../../../store/hooks';

import './ClauseItemForRelatedDocs.scss';

export const ClauseItemForRelatedDocs = ({ clause }: { clause: Clause }) => {
  const { ClauseId, ClauseName, Text, ClauseStatus, Provider, Tag } = clause;
  const dispatch = useAppDispatch();

  const handleClick = () => {
    dispatch(setScrollToClauseInDoc(ClauseId));
  };

  return (
    <div className='clause-item-related-docs' onClick={handleClick}>
      <div className='header'>
        <ItemNameText fileName={ClauseName} strong />
        <ClauseStatusIcon clauseStatus={ClauseStatus} />
        <ProviderChip provider={Provider} classname='chip chip-label' />
        <Chip label={Tag} className='chip chip-label' />
      </div>
      <div className='text' dangerouslySetInnerHTML={{ __html: generatePreview(Text) }} />
    </div>
  );
};
