import axios, { AxiosResponse, CancelTokenSource } from 'axios';
import axiosAuth from '../../../components/Auth/axiosAuth';
import {
  DOWNLOAD_METADATA_CSV_DICT_ENDPOINT,
  DOWNLOAD_METADATA_CSV_ENDPOINT,
  UPLOAD_ENDPOINT,
  UPLOAD_METADATA_CSV_ENDPOINT,
  LIST_ENDPOINT,
} from '../../../services/api.service.config';
import { UploadedFile } from '../../../components/UploadFiles/UploadedFileList.types';
import { MAX_FILES_SIZE } from '../../../config/config';

type GetListResponse = Promise<{
  data: UploadedFile[];
}>;

class UploadService {
  cancelTokens: Record<string, CancelTokenSource> = {};

  createSignedUrl(name: string) {
    return axiosAuth.post(UPLOAD_ENDPOINT, { FileName: name });
  }

  uploadFile(id: string, fileData: Record<string, string>, file: File, cb: (pr: number) => void) {
    const cancelToken = axios.CancelToken.source();
    this.cancelTokens[id] = cancelToken;

    const formPayload = new FormData();
    Object.entries(fileData.fields).forEach(([field, value]: [string, string]) => {
      formPayload.append(field, value);
    });
    formPayload.append('file', file);

    return axios.post(fileData.url, formPayload, {
      cancelToken: cancelToken.token,
      onUploadProgress: ({ loaded, total }) =>
        cb(Math.floor((loaded / (total ?? MAX_FILES_SIZE)) * 100)),
    });
  }

  cancelUpload(id: string) {
    this.cancelTokens[id]?.cancel();
  }

  async downloadMetadataCsv() {
    const forceDownload = (response: AxiosResponse) => {
      const url = window.URL.createObjectURL(new Blob([response.data], { type: 'text/csv' }));
      const link = document.createElement('a');
      link.href = url;
      link.download =
        response?.headers['content-disposition']?.split('filename=')[1] || 'metadata.csv';
      link.click();
    };

    await Promise.all([
      axiosAuth.get(DOWNLOAD_METADATA_CSV_ENDPOINT).then((response) => {
        forceDownload(response);
      }),
      axiosAuth.get(DOWNLOAD_METADATA_CSV_DICT_ENDPOINT).then((response) => {
        forceDownload(response);
      }),
    ]);
  }

  uploadMetadataCsv(file: File) {
    return axiosAuth.put(UPLOAD_METADATA_CSV_ENDPOINT, file);
  }

  preflightDocumentDownload(url: string) {
    return axios.get(url, { headers: { Range: 'bytes=0-0' } });
  }

  documentDownload(url: string) {
    return axios.get(url);
  }

  getList(): GetListResponse {
    return axiosAuth.get(LIST_ENDPOINT);
  }
}

const uploadService = new UploadService();

export default uploadService;
