import { hasWriteAccessToDoc } from './DocumentViewModal.helpers';
import { DocumentResponse } from '../../../store/files/documents/documents.list.types';
import { useGetClauseDictionariesQuery } from '../../../store/files/upload/list.service';
import { AccessMode } from '../../../store/files/documentsAndClauses/list.types';

export enum SEMI_AUTO_CLAUSE_CREATE_TOOLTIPS {
  INSUFFICIENT_RIGHTS = 'You do not have the required rights to create a clause from this document due to its company or classification.',
  DOCUMENT_REPORTED = 'You cannot create a clause from a document with the status "Reported".',
  SELECT_TEXT = 'To create a clause from this document, select the document text.',
}

const useCanCreateSemiAutoClause = (document: DocumentResponse | undefined) => {
  const { data: writeAccessDictionaries } = useGetClauseDictionariesQuery(
    {
      AccessMode: AccessMode.WRITE,
    },
    { skip: !document }
  );

  return document && writeAccessDictionaries
    ? hasWriteAccessToDoc(document.Entity, document.Classification, writeAccessDictionaries)
    : false;
};

export default useCanCreateSemiAutoClause;
