import Mark, { MarkOptions } from 'mark.js';
import { getHTMLElement } from './HtmlViewer.highlight';
import { RefObject, useEffect } from 'react';
import { useAppSelector } from '../../../store/hooks';
import { ClauseResponse } from '../../../store/files/clauses/clauses.list.types';
import viewer from './FileViewer.module.scss';
import { useSnackbar } from 'notistack';
import { ErrorMessages } from '../../../services/errors.service.types';
import { uiSelectors } from '../../../store/ui/ui.selectors';

const TAG = 'clause';
export const clauseStyles = `<style>${TAG} {background: ${viewer.clauseBackground};}</style>`;

const MARGIN_MARKER_CLASSNAME = 'margin-marker';
const marginMarker = (element: HTMLElement, first: number, last: number, scale?: number) => {
  const width = 10;
  const top = first;
  const height = last - first;

  const scaled = (num: number) => (scale ? num / scale : num);

  const margin = document.createElement('div');
  margin.classList.add(MARGIN_MARKER_CLASSNAME);
  margin.style.position = 'absolute';
  margin.style.left = '10px';
  margin.style.width = scaled(width) + 'px';
  margin.style.top = scaled(top) + 'px';
  margin.style.height = scaled(height) + 'px';
  margin.style.background = viewer.clauseBackground;

  element.append(margin);
};

const options: MarkOptions = {
  accuracy: 'partially',
  element: TAG,
  className: '',
  ignorePunctuation: ['-', '- '],
  ignoreJoiners: true,
  acrossElements: true,
  separateWordSearch: false,
  wildcards: 'enabled',
};

export const prepareSentences = (text: string) => {
  return text
    .replaceAll('\n', ' ')
    .replaceAll(/\s{2,}/g, ' ')
    .replaceAll(' , ', ', ')
    .replaceAll(/(?<=\p{Lower})(-)\s/gu, '-')
    .split(/(?<=[\p{Lower})]{3,})[.?!]\s+(?=\p{Upper}|[0-9])/gu)
    .map((v) => v.trim().replaceAll(/\d+/g, '*'));
};

const calculateTop = (parent: HTMLElement, element: Element) => {
  const offset = parent instanceof HTMLDivElement ? parent.scrollTop : 0;
  return Math.abs(
    offset - parent.getBoundingClientRect().top + element.getBoundingClientRect().top
  );
};

const getStartingAndEndingSentences = (sentences: string[], count: number) => [
  ...sentences.slice(0, count),
  ...sentences.slice(-1 * count),
];

export const useClauseHighlight = (
  ref: RefObject<HTMLIFrameElement | HTMLDivElement | null>,
  rendered: boolean,
  clauses?: ClauseResponse[],
  scale?: number
) => {
  const scrollToClauseInDoc = useAppSelector(uiSelectors.selectScrollToClauseInDoc);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (!rendered) return;

    const element = getHTMLElement(ref.current);
    if (!element) return;

    const instance = new Mark(element);
    instance.unmark(options);
    element.querySelectorAll(`.${MARGIN_MARKER_CLASSNAME}`).forEach((e) => e.remove());

    if (!scrollToClauseInDoc) return;

    const clause = clauses?.find(({ ClauseId }) => ClauseId === scrollToClauseInDoc);
    if (!clause) return;

    const { Text } = clause;

    const preparedSentences = prepareSentences(Text);
    const longClauseMode = preparedSentences.length > 10;

    const sentences = longClauseMode
      ? getStartingAndEndingSentences(preparedSentences, 3)
      : preparedSentences;
    sentences.forEach((sentence) => {
      try {
        instance.mark(sentence, options);
      } catch (e) {}
    });

    const marks = element.querySelectorAll(TAG);

    if (marks.length) {
      setTimeout(() => {
        const top = calculateTop(element, marks[0]);
        element.scrollTo({ top, behavior: 'smooth' });

        if (longClauseMode) {
          marginMarker(element, top, calculateTop(element, marks[marks.length - 1]), scale);
        }
      });
    } else {
      enqueueSnackbar(ErrorMessages.ClauseHighlightError, { variant: 'warning' });
    }
  }, [clauses, enqueueSnackbar, ref, rendered, scale, scrollToClauseInDoc]);
};
