import { useGetDocumentsListQuery } from '../../../store/files/documents/documents.list.service';
import {
  DocumentsListParams,
  DocumentStatus,
} from '../../../store/files/documents/documents.list.types';
import { AccessMode } from '../../../store/files/documentsAndClauses/list.types';
import { useGetDictionariesQuery } from '../../../store/files/upload/list.service';
import { TAB_ITEMS_PAGE_SIZE } from '../../../config/config';

export function useDocumentVersionsData(clauseId: string) {
  const queryParameters: DocumentsListParams = {
    Status: [DocumentStatus.READY],
    RelatedClauses: clauseId,
    PageSize: TAB_ITEMS_PAGE_SIZE.toString(),
  };

  const { data: documents } = useGetDocumentsListQuery(queryParameters, { skip: !clauseId });

  const { data: readAccessDictionaries } = useGetDictionariesQuery(
    { AccessMode: AccessMode.READ },
    { skip: !clauseId }
  );

  return {
    documents,
    readAccessDictionaries,
  };
}
