export interface UploadedFileDeleteButtonProps {
  FileId: string;
  type: UPLOADED_FILE_TYPE;
}

export enum UPLOADED_FILE_TYPE {
  PRE_LANDING_DOC = 'PRE_LANDING_DOC',
  PENDING_DOC = 'PENDING_DOC',
  PENDING_CLAUSE = 'PENDING_CLAUSE',
}
