import React, {
  createContext,
  useContext,
  ReactNode,
  useState,
  Dispatch,
  SetStateAction,
} from 'react';

interface ScrollSyncContextType {
  syncedScroll: number;
  setSyncedScroll: Dispatch<SetStateAction<number>>;
  isScrollSyncEnabled: boolean;
  setIsScrollSyncEnabled: Dispatch<SetStateAction<boolean>>;
}

const defaultContextValue: ScrollSyncContextType = {
  syncedScroll: 0,
  setSyncedScroll: () => {},
  isScrollSyncEnabled: false,
  setIsScrollSyncEnabled: () => {},
};

const ScrollSyncContext = createContext<ScrollSyncContextType>(defaultContextValue);

export const useScrollSync = () => useContext(ScrollSyncContext);

export const ScrollSyncProvider = ({ children }: { children: ReactNode }) => {
  const [syncedScroll, setSyncedScroll] = useState(0);
  const [isScrollSyncEnabled, setIsScrollSyncEnabled] = useState(false);

  return (
    <ScrollSyncContext.Provider
      value={{ syncedScroll, setSyncedScroll, isScrollSyncEnabled, setIsScrollSyncEnabled }}
    >
      {children}
    </ScrollSyncContext.Provider>
  );
};
