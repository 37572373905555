import React from 'react';
import { DataGridPro, GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro';
import './UploadedFileList.scss';
import NoteAltOutlinedIcon from '@mui/icons-material/NoteAltOutlined';
import { ButtonWithIcon } from '../StaticComponents/ButtonWithIcon';
import { FileTypeIcon } from '../StaticComponents/FileTypeIcon';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import CoronavirusOutlineOutlinedIcon from '@mui/icons-material/CoronavirusOutlined';
import { UploadedFileAction, UploadedFileStatus } from './UploadedFileList.types';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { cancelUpload, openModal, updateSelection } from '../../store/files/upload/upload.slice';
import { uploadSelectors } from '../../store/files/upload/upload.selectors';
import { useEditingDisabled } from './EditingDisabled.hook';
import { UploadedFileDeleteButton } from './UploadedFileDelete/UploadedFileDeleteButton';
import { showDeleteAndEditButtons } from './UploadedFileList.helper';
import { PRE_LANDING_PAGE_SIZE } from '../../config/config';
import { TEST_ID } from '../../config/test-fields-ids.config';
import { ItemNameText } from '../DocumentsAndClauses/ItemNameText/ItemNameText';
import { useGetUploadedFiles } from './useGetUploadedFiles';
import { Box, Tooltip } from '@mui/material';
import { mapErrorResponseToErrorMessageDetails } from '../../store/files/documents/documents.update.error.helpers';
import { NoItemsFound } from '../StaticComponents/NoItemsFound/NoItemsFound';
import { UPLOADED_FILE_TYPE } from './UploadedFileDelete/UploadedFileDeleteButton.types';

export const UploadedFileList = () => {
  const dispatch = useAppDispatch();
  const selected = useAppSelector(uploadSelectors.selectedDocs);
  const { docs, isLoading } = useGetUploadedFiles();

  return isLoading ? (
    <Box sx={{ textAlign: 'center', padding: '30px' }}>
      <CircularProgress />
    </Box>
  ) : docs?.length ? (
    <DataGridPro
      data-test-id={TEST_ID.UPLOAD_FILES.DOCUMENT_LIST_VIEW}
      hideFooterRowCount
      autoHeight={true}
      getRowId={(row) => row.DocumentId}
      rows={docs}
      columns={columns}
      checkboxSelection
      disableSelectionOnClick
      hideFooterPagination
      disableColumnMenu
      selectionModel={selected}
      className='upload-file-data-grid'
      onCellClick={() => {}}
      onSelectionModelChange={(selectionModel) => {
        dispatch(updateSelection({ selected: selectionModel as string[] }));
      }}
      pageSize={PRE_LANDING_PAGE_SIZE}
    />
  ) : (
    <NoItemsFound subtitle='' />
  );
};

const columns: GridColDef[] = [
  {
    field: 'fileType',
    headerName: '',
    flex: 1,
    sortable: false,
    renderCell: ({ row: { fileType } }: GridRenderCellParams<Date>) => {
      return <FileTypeIcon fileType={fileType} />;
    },
  },
  {
    field: 'description',
    headerName: '',
    flex: 15,
    sortable: false,
    renderCell: ({ row: { Status, FileName } }: GridRenderCellParams<Date>) => {
      return <ItemNameText fileName={FileName} strong={Status !== UploadedFileStatus.UPLOADING} />;
    },
  },
  {
    field: 'status',
    headerName: '',
    flex: 15,
    sortable: false,
    renderCell: ({ row: { Status, progress, Errors } }: GridRenderCellParams<Date>) => {
      let statusTypeElement = undefined;
      switch (Status) {
        case UploadedFileStatus.MISSING_DATA:
          statusTypeElement = (
            <div className='uploading-status warning'>
              <WarningAmberOutlinedIcon />
              <p className='uploading-status-text'>{Status}</p>
            </div>
          );
          break;
        case UploadedFileStatus.UPLOAD_COMPLETE:
          statusTypeElement = (
            <div className='uploading-status'>
              <DoneOutlinedIcon className='done' />
              <p className='uploading-status-text'>{Status}</p>
            </div>
          );
          break;
        case UploadedFileStatus.INFECTED:
          statusTypeElement = (
            <div className='uploading-status error'>
              <CoronavirusOutlineOutlinedIcon />
              <p className='uploading-status-text'>{Status}</p>
            </div>
          );
          break;
        case UploadedFileStatus.UPLOAD_FAILED:
        case UploadedFileStatus.ERROR:
          statusTypeElement = (
            <Tooltip
              title={<div>{mapErrorResponseToErrorMessageDetails(Errors)}</div>}
              placement='top-start'
            >
              <div className='uploading-status error'>
                <ErrorOutlineOutlinedIcon />
                <p className='uploading-status-text'>{Status}</p>
              </div>
            </Tooltip>
          );
          break;
        case UploadedFileStatus.UPLOADING:
          statusTypeElement = (
            <div className='uploading-status'>
              <CircularProgress className='uploading' size={20} />
              <p className='uploading-status-text'>
                {Status}... {progress ? progress + '%' : null}
              </p>
            </div>
          );
          break;
        case UploadedFileStatus.SCANNING:
          statusTypeElement = (
            <div className='uploading-status'>
              <CircularProgress className='uploading' size={20} />
              <p className='uploading-status-text'>{Status}</p>
            </div>
          );
          break;
        case UploadedFileStatus.DELETING:
          statusTypeElement = (
            <div className='uploading-status'>
              <CircularProgress className='uploading' size={20} />
              <p className='uploading-status-text'>{Status}</p>
            </div>
          );
          break;
        case UploadedFileStatus.SUBMITTING:
          statusTypeElement = (
            <div className='uploading-status'>
              <CircularProgress className='uploading' size={20} />
              <p className='uploading-status-text'>{Status}</p>
            </div>
          );
          break;
      }
      return statusTypeElement;
    },
  },
  {
    field: 'edit',
    headerName: '',
    flex: 6,
    sortable: false,
    renderCell: function ButtonCell({
      row: { DocumentId, edit, Status },
    }: GridRenderCellParams<Date>) {
      const dispatch = useAppDispatch();
      const isEditingDisabled = useEditingDisabled();
      const showEditMetadataButton = showDeleteAndEditButtons(Status);

      return showEditMetadataButton ? (
        <ButtonWithIcon
          disabled={isEditingDisabled || !edit}
          startIcon={<NoteAltOutlinedIcon fontSize='large' />}
          onClick={() => {
            dispatch(openModal({ id: DocumentId }));
          }}
        >
          Edit metadata
        </ButtonWithIcon>
      ) : (
        ''
      );
    },
  },
  {
    field: 'action',
    headerName: '',
    flex: 1,
    sortable: false,
    renderCell: function ButtonCell({
      row: { DocumentId, action, Status },
    }: GridRenderCellParams<Date>) {
      const dispatch = useAppDispatch();
      const showDeleteButton = showDeleteAndEditButtons(Status);

      return action === UploadedFileAction.STOP ? (
        <IconButton
          onClick={() => {
            dispatch(cancelUpload(DocumentId));
          }}
        >
          <CloseOutlinedIcon fontSize='small' />
        </IconButton>
      ) : action === UploadedFileAction.DELETE && showDeleteButton ? (
        <UploadedFileDeleteButton FileId={DocumentId} type={UPLOADED_FILE_TYPE.PRE_LANDING_DOC} />
      ) : (
        <></>
      );
    },
  },
];
