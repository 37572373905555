import { DirectionFlag } from './FileViewer.types';
import { TextItem } from 'pdfjs-dist/types/src/display/api';

const documentScales = [1, 2, 3];
const clauseScales = [0.25, 0.5, 0.75, ...documentScales];

export const calculateScale = (scale: number, dir: DirectionFlag, clauseMode = false) => {
  const scales = clauseMode ? clauseScales : documentScales;
  const lastIndex = scales.length - 1;
  const index = scales.indexOf(scale) + dir;
  const nextIndex = index < 0 ? 0 : lastIndex < index ? lastIndex : index;
  return scales[nextIndex];
};

export const generatePages = (pages: number) => Array.from(new Array(pages), (_, i) => i + 1);

export const handleFnOnAllPagesRendered = (
  renderedPages: number[],
  page: number,
  pagesCount: number,
  fn: () => void
) => {
  let rendered = [...renderedPages, page];
  if (new Set(rendered).size === pagesCount) {
    rendered = [];
    fn();
  }
  return rendered;
};

export const customTextRenderer = ({ str }: TextItem) => {
  const trimmed = str.trim();
  const space = trimmed[trimmed.length - 1] !== '-' ? ' ' : '';
  return str + space;
};
