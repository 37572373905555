import { AccessMode } from '../documentsAndClauses/list.types';

export type DocumentDownloadResponse = {
  document_id: string;
  url: string;
};

export interface DictionariesParams {
  AccessMode: AccessMode;
}

export type MetadataDictionaryValue = {
  label: string;
  value: string;
  related?: MetadataDictionaryRelated;
  validation_rules?: MetadataDictionaryValidationRule[];
  disabled?: boolean;
};

export interface MetadataDictionaryRelated {
  Classification?: string[];
  Lop?: string[];
  Country?: string;
  Language?: string;
  Lob?: string[];
  BusinessType?: string | string[];
}

export type RelatedValidationRule = Partial<Record<'Lop' | 'BusinessType', string[]>>;

export interface MetadataDictionaryValidationRule {
  type: string;
  values: string[];
  related: RelatedValidationRule;
}

export type MetadataDictionary = {
  required: boolean;
  values: MetadataDictionaryValue[];
};

export type DictionariesResponse = {
  Active: MetadataDictionary;
  BusinessType: MetadataDictionary;
  Classification: MetadataDictionary;
  Country: MetadataDictionary;
  DocumentType: MetadataDictionary;
  Entity: MetadataDictionary;
  ExpireAt: MetadataDictionary;
  Language: MetadataDictionary;
  LatestVersion: MetadataDictionary;
  Lob: MetadataDictionary;
  Lop: MetadataDictionary;
  Remarks: MetadataDictionary;
  ReportedReason: MetadataDictionary;
  ValidFrom: MetadataDictionary;
  ValidTo: MetadataDictionary;
  Version: MetadataDictionary;
  ProductType: MetadataDictionary;
  InsurerIndustry: MetadataDictionary;
};

export type ClauseDictionariesResponse = {
  BusinessTypes: MetadataDictionary;
  Classification: MetadataDictionary;
  ClauseNumber: MetadataDictionary;
  ClauseSources: MetadataDictionary;
  ClauseStatus: MetadataDictionary;
  ClauseTypes: MetadataDictionary;
  Countries: MetadataDictionary;
  Entities: MetadataDictionary;
  IndividualizationReason: MetadataDictionary;
  Language: MetadataDictionary;
  Lobs: MetadataDictionary;
  Lops: MetadataDictionary;
  ReportedReason: MetadataDictionary;
  Provider: MetadataDictionary;
};

export type DictionariesResponseKeys = keyof DictionariesResponse;

export interface UploadedFileRequest {
  DocumentId: string;
  FileName: string;
  Entity?: string;
  Country?: string;
  Lob?: string;
  Lop?: string;
  BusinessType?: string;
  DocumentType?: string;
  Classification?: string;
  Language?: string;
  Active?: string;
  ValidFrom?: string;
  ValidTo?: string;
  ExpireAt?: string;
  Version?: string;
  LatestVersion?: string;
  Remarks?: string;
}

export const UPDATE_METADATA_CACHE_KEY = 'update_metadata_cache_key';

export enum LIST_API_TAGS {
  LIST = 'List',
  FILE = 'File',
}

export interface ReportedReason {
  required: boolean;
  values: Array<{
    label: string;
    value: string;
  }>;
}

export enum PROVIDER {
  GPC = 'GPC',
  COMPLIANCE = 'COMPLIANCE',
  DEFAULT = 'default',
}
